import {put, takeEvery, takeLatest, takeLeading, select} from "redux-saga/effects";
import axios from "axios";
import InvestigationAction from "./investigationAction";
import config from "../../config/config";
import {authHeader} from "../../helpers/authHeader";
import SettingsAction from "../settings/settingsAction";
import {
  prepareCasesHierarchy,
  prepareInputLinkAnalysis,
  prepareUsersHierarchy,
  prepareWebintTargets
} from "./investigationTransformer";
import {
  apiAnalyticsService,
  apiCaseService,
  apiInvestigationService,
  apiProfileService,
  apiWebintService
} from "../../config/api";
import {getChosenItem, getChosenMode, getHierarchy, getInvestigationFilters} from "./investigationSelector";
import {getUser} from "../auth/authSelector";
import {selectUsersPermissions} from "../users/usersSelector";

function* getInvestigationListSaga(action) {
  const {pathId = null} = action.payload;
  yield put(InvestigationAction.setInvestigationLoading(true));

  try {
    const {phrase = '', from = null, to = null} = yield select(getInvestigationFilters);
    const { username } = yield select(getUser)
    let apiResult = {};

    //collect filters
    const filtersTree = {};
    if (phrase) {
      filtersTree.keyword = phrase;
      filtersTree.type = 'NAME';
    }
    if (from) {
      filtersTree.from = from;
    }
    if (to) {
      filtersTree.to = to;
    }

    if (Object.keys(filtersTree)?.length === 0) {
      apiResult = yield apiInvestigationService.getAllInvTree();
    } else {
      apiResult = yield apiInvestigationService.getFiltredAllInvTree(filtersTree);
    }

    const {data} = apiResult;

    yield put(InvestigationAction.clearHierarchy());

    const hierarchy = prepareUsersHierarchy(data);
    yield put(InvestigationAction.setHierarchy(hierarchy));
    yield put(InvestigationAction.setInvestigationLoading(false));
    //console.log('pathId', pathId);
    if (pathId) {
      const currentInv = hierarchy.find(item => item.type === 'investigation' && item.id === pathId * 1);
      //console.log('currentInv', currentInv);
      if (currentInv) {
        yield put(InvestigationAction.setTreeKey({
          node: { key: `investigation-${currentInv.id}`, type: 'investigation', obj: currentInv.node,}
        }))
      }
    } else {
      if (username) {
        const currentUser = hierarchy.find(item => item.type === 'user' && item.id === username);
        if (currentUser) {
          yield put(InvestigationAction.setTreeKey({
            node: { key: `user-${currentUser.id}`, type: 'user', obj: currentUser.node,}
          }))
        }
      }
    }
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield put(InvestigationAction.setInvestigationLoading(false));
  }
}

function* getCasesListSaga(action) {
  const {investigationId, pathId = null, callback} = action.payload;
  //console.log('getCasesListSaga', action.payload);

  try {
    const {phrase = ''} = yield select(getInvestigationFilters);
    const permissions = yield select(selectUsersPermissions);
    const modules = [];
    if (permissions?.['module.webint']) {
      modules.push('WEBINT')
    }
    if (permissions?.['module.profiler']) {
      modules.push('PROFILER')
    }

    let apiResult = {};
    if (!phrase) {
      apiResult = yield apiInvestigationService.getCasesByInvNumber(investigationId);
    } else {
      apiResult = yield apiInvestigationService.getFiltredCasesByInvNumber(investigationId, {keyword: phrase, type: 'NAME'})
    }
    //apiResult = yield apiInvestigationService.getCasesByInvNumber(investigationId);
    const {data} = apiResult;

    //SET HIERARCHY here
    const currentHierarchy = yield select(getHierarchy);
    const hierarchy = prepareCasesHierarchy(investigationId, data, currentHierarchy, modules);
    const preparedData = hierarchy.map(inv => inv.node);

    yield put(InvestigationAction.setHierarchy(hierarchy));
    yield put(InvestigationAction.setChosenList(preparedData));
    //console.log('getCasesListSaga', data, preparedData, hierarchy);

    //set selected key if there is path id
    if (pathId) {
      const currentCase = hierarchy.find(item => item.type === 'case' && item.id === pathId * 1);
      //console.log('currentCase', currentCase);
      if (currentCase) {
        yield put(InvestigationAction.setTreeKey({
          node: {key: `case-${currentCase.id}`, type: 'case', obj: currentCase.node, invId: currentCase.invId}
        }));
      }
    }

    //yield delay(1000);
    callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* createInvestigationSaga(action) {
  const {name, callback} = action.payload;
  const {keysArray = []} = yield select(getChosenItem);

  try {
    /*const {data} = yield axios.post(
      `${config.api_investigation}`,
      { invName: name },
      { headers: authHeader()}
    );*/
    const {data} = yield apiInvestigationService.investigationCreate({ invName: name });
    yield put(InvestigationAction.createInvestigationSuccess({
      ...data.investigation,
      keysArray: [...keysArray, `investigation-${data?.invNumber}`]
    }));
    //yield put(InvestigationAction.getInvestigationList());

    yield callback();
  } catch (e) {
    //yield put(InvestigationAction.investigationError(true, e.message));
    yield put(SettingsAction.handleAPIError(e));
    yield callback();
  }
}

function* deleteInvestigationSaga(action) {
  const id = action.payload;

  try {
    /*yield axios.delete(
      `${config.api_investigation}?invNumber=${id}`,
      {
        headers: authHeader(),
        //data: { invNumber: id }
      }
    );*/
    yield apiInvestigationService.deleteByInvNumber(id);
    yield put(InvestigationAction.deleteInvestigationSuccess(id));
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* createCaseSaga(action) {
  const {name, invId, type, callback} = action.payload;
  const {keysArray = []} = yield select(getChosenItem);

  try {
    /*const {data} = yield axios.post(
      `${config.api_case}`,
      { name: name, invID: invId, caseType: type },
      { headers: authHeader()}
    );*/
    const {data} = yield apiCaseService.createCase({ name: name, invID: invId, caseType: type });
    yield put(InvestigationAction.createCaseSuccess({
      ...data.caze,
      id: data.caze?.caseNumber,
      invNumber: invId,
      keysArray: [...keysArray, `case-${data?.caze?.caseNumber}`]
    }));

    yield callback();
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback();
  }
}

function* deleteCaseSaga(action) {
  const id = action.payload;

  try {
    /*yield axios.delete(
      `${config.api_case}?caseNumber=${id}`,
      {
        headers: authHeader(),
        //data: { invNumber: id }
      }
    );*/
    yield apiCaseService.deleteByCaseNumber(id)
    yield put(InvestigationAction.deleteCaseSuccess(id));
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getTargetsListSaga(action) {
  const {caseId, type = 'PROFILER', paginateObj} = action.payload;
  const {page = 0, size = config.targetListPageSize} = paginateObj;
  //console.log('paginate obj', paginateObj)

  yield put(InvestigationAction.setTargetsLoading(true));

  try {
    let data = [];
    const {phrase = ''} = yield select(getInvestigationFilters);

    // eslint-disable-next-line
    switch (type) {
      case "PROFILER":
        /*const {data: profilerTargets} = !phrase
          ? yield apiCaseService.getCaseProfiles(caseId, page, size)
          : yield apiCaseService.getFiltredCaseProfiles(caseId, page, size, {keyword: phrase, type: 'NAME'});*/

        const {data: profilerTargets} = yield apiCaseService.getCaseProfiles(caseId, page, size);
        data = profilerTargets;
        break;
      case "WEBINT":
        /*const {data: webintTargets} = !phrase
          ? yield apiCaseService.getCaseWebins(caseId, page, size)
          : yield apiCaseService.getFiltredCaseWebins(caseId, page, size, {keyword: phrase, type: 'NAME'});*/

        const {data: webintTargets} = yield apiCaseService.getCaseWebins(caseId, page, size);
        data = prepareWebintTargets(webintTargets);
        break;
    }

    //console.log('getTargetsListSaga', data);

    //SET HIERARCHY here
    yield put(InvestigationAction.setChosenList(data));

  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield put(InvestigationAction.setChosenList([]));
  } finally {
    yield put(InvestigationAction.setTargetsLoading(false));
  }
}

function* createProfilerTargetSaga(action) {
  const {caseId, formData, callback} = action.payload;

  try {
    //const {data} = yield apiCaseService.createProfile(caseId, formData, {headers: {'content-type': 'multipart/form-data'}});
    /*for (var value of formData?.values()) {
      console.log('formData', value);
    }*/

    console.log('formData', formData/*, formData.values()*/);
    const {data} = yield axios.post(`${config.api_case}/${caseId}/profile`, formData,{
        headers: {
          ...authHeader(),
          "Content-Type": "multipart/form-data"
        }
      });

    yield put(InvestigationAction.createProfilerTargetSuccess(data));
    console.log('createProfilerTargetSaga', data);

    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback();
  }
}

function* createWebintTargetSaga(action) {
  const {caseId, formData, callback} = action.payload;

  try {
    //const {data} = yield apiCaseService.createProfile(caseId, formData, {headers: {'content-type': 'multipart/form-data'}});
    /*for (var value of formData?.values()) {
      console.log('formData', value);
    }*/

    const {data} = yield axios.post(`${config.api_case}/${caseId}/webint`, formData,{
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data"
      }
    });

    console.log('createWebintTargetSaga', data);
    yield put(InvestigationAction.createProfilerTargetSuccess([data]));


    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback();
  }
}

function* deleteTargetSaga(action) {
  const {id, type = 'PROFILER'} = action.payload;

  try {
    if (type === 'PROFILER') {
      yield apiProfileService.deleteProfile(id)
    }
    if (type === 'WEBINT') {
      yield apiWebintService.deleteWebintById(id);
    }

    yield put(InvestigationAction.deleteTargetSuccess(id));
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* getLinkAnalysisSaga(action) {
  const callback = action.payload;
  const currentItem = yield select(getChosenItem);
  const currentMode = yield select(getChosenMode);
  const preparedInput = prepareInputLinkAnalysis(currentItem, currentMode);

  console.log('currentItem', currentItem, currentMode);
  try {
    const {data: connections} = yield apiAnalyticsService.connection(preparedInput);
    yield callback(connections);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback([]);
  }
}

function* setFiltersSaga(action) {
  const current = yield select(getInvestigationFilters);
  console.log('SAGA', current);
  try {
    yield put(InvestigationAction.clearHierarchy());
    yield put(InvestigationAction.getInvestigationList());
  } catch (e) {
    console.log('setFiltersSaga.error', e);
  }
}


export function* watchInvestigation() {
  yield takeLeading(InvestigationAction.GET_INVESTIGATION_LIST, getInvestigationListSaga);
  yield takeLeading(InvestigationAction.GET_CASES_LIST, getCasesListSaga);
  yield takeEvery(InvestigationAction.CREATE_INVESTIGATION, createInvestigationSaga);
  yield takeEvery(InvestigationAction.DELETE_INVESTIGATION, deleteInvestigationSaga);
  yield takeEvery(InvestigationAction.CREATE_CASE, createCaseSaga);
  yield takeEvery(InvestigationAction.DELETE_CASE, deleteCaseSaga);
  yield takeEvery(InvestigationAction.GET_TARGETS_LIST, getTargetsListSaga);
  yield takeEvery(InvestigationAction.CREATE_PROFILER_TARGET, createProfilerTargetSaga);
  yield takeEvery(InvestigationAction.CREATE_WEBINT_TARGET, createWebintTargetSaga);

  yield takeEvery(InvestigationAction.DELETE_TARGET, deleteTargetSaga);
  yield takeEvery(InvestigationAction.GET_LINK_ANALYSIS, getLinkAnalysisSaga);
  yield takeLatest(InvestigationAction.SET_FILTERS, setFiltersSaga);
}

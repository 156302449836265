import React from 'react';
import {Page, Text, View, Document, Font} from '@react-pdf/renderer';
import {withTranslation} from "react-i18next";
import font from "../../../../assets/fonts/arial-unicode-ms.ttf";
import {EXT_TITLE, VERSION} from "../../../../config/consts";
import {styles} from "./styles";
import MainPage from "./MainPage";
import PersonalInfo from "./PersonalInfo";
import Phones from "./Phones";
import MainInfo from "./MainInfo";
import Profiles from "./Profiles";
import ContactInfo from "./ContactInfo";
import Preferences from "./Preferences";
import Images from "./Images";
import Summary from "./Summary";
import Posts from "./Posts";
import SearchResults from "./SearchResults";
import Friends from "./Friends";
import WebResults from "./WebResults";
import Locations from "./Locations";
import {SupportService} from "../../../support";
import DeepWeb from "./DeepWeb";
import config from "../../../../config/config";

Font.register({
  family: 'pdfFont',
  src: font
});
Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/2/72x72/',
});




const ExportPdfProfiler = ({t, data}) => {
  const { synopsis } = data;

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>{t("Created by")} {config.appTitle || EXT_TITLE} {VERSION}</Text>
        <View style={styles.headerPadding} fixed/>


        <MainPage data={data} />
        <Text break/>

        <PersonalInfo data={data} />
        <Phones data={data} />
        <MainInfo data={data} />
        <Text break/>

        <Profiles data={data} />
        <Text break/>

        <ContactInfo data={data} />
        <Text break/>

        <Preferences data={data} />
        <Text break/>

        <Images data={data} />
        <Text break/>

        <Summary data={data} />
        <Text break />

        <Posts data={data} />
        <Text break />

        <SearchResults data={data} />
        <Text break />

        <Friends data={data} />
        <Text break />

        <WebResults data={data} />
        <Text break />

        <Locations data={data} />
        <Text break />

        <DeepWeb data={data} />
        <Text break />

        <Text style={{marginBottom: '20px'}}>{t("Synopsis")}</Text>
        <Text style={{fontSize: '12px', fontFamily: 'pdfFont',}}>{SupportService.stripHtmlTags(synopsis)}</Text>

        <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
          `${pageNumber} / ${totalPages}`
        )} fixed/>
      </Page>
    </Document>
  )
};


export default withTranslation()(ExportPdfProfiler);